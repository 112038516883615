import React, { useRef } from "react";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";
// import Button from '@material-ui/core/Button';
import videoSrc from "./images/4335_home.mp4";
import headerPic from "./images/1.jpg";
import image2 from "./images/3.jpg";
import Footer from "./Footer.js";
import thumbnailSrc from "./images/home-thumbnail.png";
import Header from "./Header.js";

import styles from "./Home.module.css";
import "./modal.css";

export default function Home() {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    // Fire an event when the button is clicked
    ReactGA.event({
      category: "User",
      action: "Clicked Navigate to Interview",
      label: "Interview Page",
    });
    navigate("/interview");
  };
  const videoRef = useRef(null);
  return (
    <div>
      <div className={styles.homeContainer}>
        <div className={styles.mainContent}>
          <Header />
          <div className={styles.heroContent}>
            <img src={headerPic} alt="hero-pic" className={styles.heroPic} />
            <div className={styles.heroRow}>
              <h1 className={styles.home}>
                Simplify your W-4 filing process{" "}
                <span style={{ color: "#f1a358", fontStyle: "italic" }}>
                  together
                </span>
              </h1>

              <p className={styles.home}>
                With W-4married.com, you can tackle your withholdings together,
                stress-free, and affordable. For just $2.99, our simple form
                filler turns tax prep into teamwork—because nothing says
                'romance' quite like accurate tax withholding and financial
                peace of mind.
              </p>
              <div className={styles.cta}>
                <button onClick={handleButtonClick}>GET STARTED</button>
              </div>
            </div>
          </div>

          <div className={styles.heroContent}>
            <div className={styles.heroRow}>
              <h2 className={styles.home}>
                Filing jointly and feeling frustrated with completing your W-4?
              </h2>
              <p className={styles.home}>
                Filing jointly and feeling frustrated with completing your W-4?
                Ensure your withholdings are accurate by completing your W-4
                forms together! We make it easy with a simple, step-by-step tool
                that guides you through a few quick questions, handles the
                calculations, and lets you print or email your completed forms
                in minutes—all for only $2.99!
              </p>
              <div className={styles.cta}>
                <button onClick={handleButtonClick}>LEARN MORE</button>
              </div>
            </div>

            <img
              src={image2}
              alt="woman working on laptop"
              className={styles.heroPic}
            />
          </div>
          <div className={styles.heroContent}>
            <div className={styles.heroVideo}>
              <h2 className={styles.home}>Why do I need two W-4s?</h2>
              <video
                ref={videoRef}
                src={videoSrc}
                poster={thumbnailSrc}
                controls
                style={{ width: "100%" }}
              />
            </div>
            <div className={styles.whatYouGet}>
              <h2 className={styles.home}>What you get:</h2>
              <ul>
                <li className={styles.home}>
                  <b>Step-by-Step Guidance</b>: Easily complete your W-4 forms
                  with simple, guided prompts that gather all the necessary
                  details.
                </li>
                <li className={styles.home}>
                  <b>Personalized W-4 Forms</b>: Receive ready-to-use, accurate
                  W-4s for yourself or both spouses—ready to email or save in
                  seconds.
                </li>
                <li className={styles.home}>
                  <b>Flexible Adjustments</b>: Customize dependents, credits,
                  and deductions to suit your unique financial situation.
                </li>
                <li className={styles.home}>
                  <b>Privacy Guaranteed</b>: Your personal information is never
                  stored, ensuring complete confidentiality.
                </li>
                <li className={styles.home}>
                  <b>Peace of Mind</b>: Feel confident knowing your withholdings
                  are accurate and optimized for your financial goals.
                  <br />
                  <br />
                  <b>All this for just $2.99!</b>
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.cta}>
            <button onClick={handleButtonClick}>GET STARTED NOW</button>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}
