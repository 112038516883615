// updated 2/26/24
const householdTable = [
  [0, 450, 850, 1000, 1020, 1020, 1020, 1020, 1870, 1870, 1870, 1890],
  [450, 1450, 2000, 2200, 2220, 2220, 2220, 3180, 4070, 4070, 4090, 4290],
  [850, 2000, 2600, 2800, 2820, 2820, 3780, 4780, 5670, 5690, 5890, 6090],
  [1000, 2200, 2800, 3000, 3020, 3980, 4980, 5980, 6890, 7090, 7290, 7490],
  [1020, 2220, 2820, 3830, 4850, 5850, 6850, 8050, 9130, 9330, 9530, 9730],
  [1020, 3030, 4630, 5830, 6850, 8050, 9250, 10450, 11530, 11730, 11930, 12130],
  [
    1870, 4070, 5670, 7060, 8280, 9480, 10680, 11880, 12970, 13170, 13370,
    13570,
  ],
  [
    1950, 4350, 6150, 7550, 8770, 9970, 11170, 12370, 13450, 13650, 14650,
    15650,
  ],
  [
    2040, 4440, 6240, 7640, 8860, 10060, 11260, 12860, 14740, 15740, 16740,
    17740,
  ],
  [
    2040, 4440, 6240, 7640, 8860, 10860, 12860, 14860, 16740, 17740, 18940,
    20240,
  ],
  [
    2040, 4440, 6640, 8840, 10860, 12860, 14860, 16910, 19090, 20390, 21690,
    22990,
  ],
  [
    2720, 5920, 8520, 10960, 13280, 15580, 17880, 20180, 22360, 23660, 24960,
    26260,
  ],
  [
    2970, 6470, 9370, 11870, 14190, 16490, 18790, 21090, 23280, 24580, 25880,
    27180,
  ],
  [
    3140, 6840, 9940, 12640, 15160, 17660, 20160, 22660, 25050, 26550, 28050,
    29550,
  ],
];
// TODO: remember the last case needs to be over 110,000
const householdColumns = [
  9999, 19999, 29999, 39999, 49999, 59999, 69999, 79999, 89999, 99999, 109999,
  120000,
];

// TODO: remember the last case needs to be over 450,000
const householdRows = [
  9999, 19999, 29999, 39999, 59999, 79999, 99999, 124999, 149999, 174999,
  199999, 249999, 450000,
];

const householdCalculation = (income1, income2) => {
  const higherNumber = Math.max(income1, income2);
  const lowerNumber = Math.min(income1, income2);

  let rowIndex = householdRows.findIndex((range) => higherNumber <= range);
  let columnIndex = householdColumns.findIndex((range) => lowerNumber <= range);

  // If income is higher than all ranges, use the highest available index
  if (rowIndex === -1) {
    rowIndex = householdRows.length;
  }

  if (columnIndex === -1) {
    columnIndex = householdColumns.length;
  }

  // Ensure the indices are within bounds of the singleTable
  rowIndex = Math.min(rowIndex, householdTable.length - 1);
  columnIndex = Math.min(columnIndex, householdTable[rowIndex].length - 1);

  return householdTable[rowIndex][columnIndex];
};

export { householdCalculation };
