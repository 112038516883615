import { PDFDocument } from "pdf-lib";
import React, { useState, useEffect } from "react";
import w4 from "./images/w4page1.pdf";

async function fillPDF(formInput) {
  const formUrl = w4;
  const formPdfBytes = await fetch(formUrl).then((res) => res.arrayBuffer());
  const pdfDoc = await PDFDocument.load(formPdfBytes);

  const form = pdfDoc.getForm();
  const firstName = form.getTextField("first_name");
  const lastName = form.getTextField("last_name");
  const address = form.getTextField("address");
  const cityStateZip = form.getTextField("city_state_zip");
  const ssn = form.getTextField("ssn");

  const single = form.getCheckBox("single");
  const married = form.getCheckBox("joint");
  const household = form.getCheckBox("household");

  // const jobs = form.getCheckBox("2jobs");
  const children = form.getTextField("qualifying_children");
  const dependents = form.getTextField("other_dependents");
  const amounts = form.getTextField("total_credits");
  const itemize = form.getTextField("deductions");
  const otherIncome = form.getTextField("other_Income");
  const withholding = form.getTextField("extra_withholding");
  // const signature = form.getTextField("signature");
  const date = form.getTextField("Date");

  firstName.setText(`${formInput?.firstName}`);
  lastName.setText(`${formInput?.lastName}`);
  address.setText(`${formInput?.address}`);
  cityStateZip.setText(
    `${formInput?.city}, ${formInput?.state}  ${formInput?.zip}`
  );

  ssn.setText(
    formInput?.ssn
      ? formInput.ssn.replace(/^(\d{3})(\d{2})(\d{4})$/, "$1-$2-$3")
      : "" // Default to empty string if ssn is undefined
  );
  const primaryIncomePercentage = () => {
    return (
      Number(formInput.income1) /
      (Number(formInput.income1) + Number(formInput.income2))
    );
  };

  if (formInput?.taxFilingStatus === "single") {
    single.check();

    if (formInput.deductionType === "itemized") {
      let netItemizedAmount = Number(formInput.totalAmountToItemize) - 15000;
      itemize.setText(
        String(
          Number(netItemizedAmount > 0 ? netItemizedAmount : 0)
            .toFixed(0)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        )
      );
    } else {
      itemize.setText("0");
    }
  } else if (formInput?.taxFilingStatus === "married") {
    married.check();
    if (formInput.deductionType === "itemized") {
      let proportionPrimaryOfStandard = 30000 * primaryIncomePercentage();
      let primaryItemizedNet =
        Number(formInput.amountToItemize) - Number(proportionPrimaryOfStandard);
      itemize.setText(
        String(
          Number(primaryItemizedNet > 0 ? primaryItemizedNet : 0)
            .toFixed(0)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        )
      );
    } else {
      itemize.setText("0");
    }
  } else if (formInput?.taxFilingStatus === "household") {
    household.check();
    if (formInput.deductionType === "itemized") {
      let netItemizedAmount = Number(formInput.totalAmountToItemize) - 22500;
      itemize.setText(
        String(
          Number(netItemizedAmount > 0 ? netItemizedAmount : 0)
            .toFixed(0)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        )
      );
    } else {
      itemize.setText("0");
    }
  }

  const totalIncome =
    Number(formInput?.income1) +
    Number(formInput?.income2) +
    Number(formInput?.otherIncome) +
    Number(formInput?.otherIncomeSpouse);
  if (
    (formInput?.taxFilingStatus === "married" && totalIncome <= 400000) ||
    totalIncome <= 200000
  ) {
    const childrenCalculation = (Math.floor(formInput?.children || 0) * 2000)
      .toFixed(0)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    children.setText(childrenCalculation);

    const dependentsCalculation = (
      Math.floor(formInput?.otherDependents || 0) * 500
    )
      .toFixed(0)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    dependents.setText(dependentsCalculation);

    const step3 = (
      Math.floor(formInput?.children || 0) * 2000 +
      Math.floor(formInput?.otherDependents || 0) * 500 +
      Math.floor(formInput?.credits || 0)
    )
      .toFixed(0)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    amounts.setText(step3);
  } else {
    amounts.setText(`0`);
  }

  const otherIncomeFormatted = Math.floor(formInput?.otherIncome || 0)
    .toFixed(0)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  otherIncome.setText(otherIncomeFormatted);

  const withholdingPrimaryFormatted = Math.floor(
    formInput?.withholdingPrimary || 0
  )
    .toFixed(0)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  withholding.setText(withholdingPrimaryFormatted);
  // signature.setText(`${formInput?.firstName} ${formInput.lastName}`);
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  date.setText(formattedDate);

  const pdfBytes = await pdfDoc.save();
  const blob = new Blob([pdfBytes], { type: "application/pdf" });
  const docUrl = URL.createObjectURL(blob);
  return docUrl;
}

function FillForm({ formInput }) {
  const [pdfInfo, setPdfInfo] = useState();

  useEffect(() => {
    const fillAndLoadPDF = async () => {
      const pdfUrl = await fillPDF(formInput);
      setPdfInfo(pdfUrl);
    };

    fillAndLoadPDF();
  }, [formInput]);

  return (
    <>
      {pdfInfo && (
        <object
          data={pdfInfo}
          type="application/pdf"
          style={{ width: "100%", height: "100vh" }}
        >
          <p>
            It appears your browser does not support embedded PDFs. Please{" "}
            <a href={pdfInfo} target="_blank" rel="noreferrer">
              click here
            </a>{" "}
            to view your Form W-4.
          </p>
        </object>
      )}
    </>
  );
}

export default FillForm;

//   download form:
// var blob = new Blob([pdfBytes], {type: "application/pdf"});
// var link = document.createElement("a");
// link.href = window.URL.createObjectURL(blob);
// link.download = "myFileName.pdf";
// link.click();
