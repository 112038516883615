import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom"; // Moved useLocation to inside Router
import "./App.css";
import Home from "./views/Home.js";
import Interview from "./views/Interview.js";
import FillForm from "./views/PdfFill.js";
import TermsOfService from "./views/TermsOfService.js";
import PrivacyPolicy from "./views/PrivacyPolicy.js";
import Dashboard from "./views/Dashboard.js";
import Faq from "./views/Faq.js";
import Founder from "./views/Founder.js";
// import GenerateCoupons from "./views/CreateCoupons.js";

// This component handles the GA tracking
function Analytics() {
  const location = useLocation(); // Hook to get the current route

  useEffect(() => {
    // Initialize GA
    ReactGA.initialize("G-5WW4KB5WQZ");

    // Send a pageview event when the location (route) changes
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return null; // This component doesn’t render anything
}

function App() {
  return (
    <Router>
      {/* This will handle the Google Analytics page views */}
      <Analytics />

      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/interview" element={<Interview />} />
        <Route exact path="/completed-w4" element={<FillForm />} />
        <Route exact path="/terms-of-service" element={<TermsOfService />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route exact path="/faq" element={<Faq />} />
        <Route exact path="/founder" element={<Founder />} />
        {/* <Route
          exact
          path="/generate-stripe-coupons"
          element={<GenerateCoupons />}
        /> */}
      </Routes>
    </Router>
  );
}

export default App;
