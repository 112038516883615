import React, { useRef } from "react";
import Footer from "./Footer";
import styles from "./Interview.module.css";
import Header from "./Header";

export default function Founder() {
  const videoRef = useRef(null);

  return (
    <>
      <div id="page-container">
        <Header />
        <div className={styles.toc}>
          <h1 style={{ marginBottom: 0, marginTop: 0 }}>
            A Message from Our Founder, Jack Hercher
          </h1>
          <div className={styles.founderVideo}>
            <iframe
              ref={videoRef}
              src="https://player.vimeo.com/video/1047603624?title=0&amp;byline=0&amp;portrait=0"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              title="A Word from our Founder 01-15-25"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            />

            {/* <video
              ref={videoRef}
              src="https://vimeo.com/1047603624"
              poster={thumbnailSrc}
              controls
              style={{ width: "60%" }}
            /> */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
