import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Home.module.css";

export default function Navigation() {
  const navigate = useNavigate();
  return (
    <div>
      <ul>
        <li className={styles.navItems} onClick={() => navigate("/interview")}>
          Start Interview
        </li>
        <li className={styles.navItems} onClick={() => navigate("/founder")}>
          A Message from Our Founder
        </li>
        <li className={styles.navItems} onClick={() => navigate("/faq")}>
          Frequently Asked Questions
        </li>
        <li
          className={styles.navItems}
          onClick={() => navigate("/terms-of-service")}
        >
          Terms of Service
        </li>
        <li
          className={styles.navItems}
          onClick={() => navigate("/privacy-policy")}
        >
          Privacy Policy
        </li>
        <li className={styles.navItems} onClick={() => navigate("/")}>
          Home
        </li>
      </ul>
    </div>
  );
}
