import React from "react";
import styles from "./Footer.module.css";
import facebook from "./images/icons8-facebook.svg";

export default function Footer() {
  return (
    <div className={styles.footerBlock}>
      <div className={styles.footer}>
        <div className={styles.footerContainer}>
          <div>
            <span
              style={{ marginBottom: 0, cursor: "pointer" }}
              onClick={() => window.open("/privacy-policy", "_blank")}
            >
              PRIVACY POLICY
            </span>
          </div>
          <div>
            <span
              style={{ marginTop: 0, cursor: "pointer" }}
              onClick={() => window.open("/terms-of-service", "_blank")}
            >
              TERMS OF SERVICE
            </span>
          </div>
          <div>
            <span
              style={{ marginTop: 0, cursor: "pointer" }}
              onClick={() => window.open("/faq", "_blank")}
            >
              FAQ
            </span>
          </div>
        </div>

        <div className={styles.footerContainerRight}>
          <div>
            <span style={{ marginBottom: 0, cursor: "pointer" }}>
              Copyright © 2025 by TaxLucent
            </span>
          </div>
          <div>
            <span
              style={{ marginTop: 0, cursor: "pointer" }}
              onClick={() => window.open("mailto:info@w-4married.com")}
            >
              info@w-4married.com
            </span>
          </div>
          <div
            style={{ marginTop: 0, cursor: "pointer" }}
            onClick={() =>
              window.open("https://www.facebook.com/w4married", "_blank")
            }
          >
            <img src={facebook} alt="Facebook icon" />
          </div>
        </div>
      </div>
    </div>
  );
}
